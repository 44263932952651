<template>
  <div class="resume-header">
    <div class="left-block">
      <h1>
        {{ resume.firstName }} <span>{{ resume.lastName }}</span>
      </h1>
    </div>
    <div class="avatar-block">
      <img :src="avatar" :alt="`${resume.firstName} ${resume.lastName}`" />
    </div>
    <div class="contact">
      <!-- <h1>{{ resume.phoneNumber }}</h1> -->
      <h2>{{ resume.website }}</h2>
      <h2>{{ resume.email }}</h2>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ResumeAvatar from "../../assets/resume-avatar.png";
export default {
  computed: {
    ...mapState({ resume: "resume" }),
  },
  data() {
    return {
      avatar: ResumeAvatar,
    };
  },
};
</script>

<style scoped>
.resume-header {
  display: flex;
  justify-content: center;
  background-color: #f7f7f7;
  margin-left: -0.75in;
  width: 21cm;
  padding: 5mm;
  margin-bottom: 1cm;
}

.left-block {
  display: flex;
  align-items: center;
  text-align: right;
}

.avatar-block {
  width: 3cm;
  margin: 0 1cm;
}

.contact {
  display: flex;
  flex-direction: column;
  margin-right: -10mm;
}

img {
  width: 3cm;
  height: 3cm;
  position: absolute;
  top: 22mm;
}

h1 {
  color: #414042;
}

h1 > span {
  color: #33cc99;
}

@media print {
  .avatar-block {
    transform: translateY(-3cm);
  }
}
</style>
