<template>
  <div id="hello" class="padded-div">
    <h2 class="hash" data-aos="fade-in">#Hello</h2>
    <h1 id="name" data-aos="fade-in">Lykaio Wang</h1>
    <h3 id="title" data-aos="fade-in">
      Experienced Leader in Software Engineering.
    </h3>
    <div class="button-group">
      <!-- <button v-on:click="scrollTo('stack')" >
        See Tech Stack
      </button> -->
      <router-link to="/resume">
      <button >
        View Resumé
      </button>
      </router-link>
    </div>
  </div>
</template>

<script>
import scroll from "zenscroll";
export default {
  name: "intro",
  methods: {
    scrollTo(targetId) {
      let target = document.getElementById(targetId);
      let defaultDuration = 500;
      let edgeOffset = 0;
      scroll.setup(defaultDuration, edgeOffset);
      scroll.to(target);
    },
  },
};
</script>

<style scoped>
#hello {
  width: 100vw;
  height: 100vh;
  background-image: url("../../assets/img/intro-bg.jpg");
  background-color: #20534a;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: auto;
}

h1 {
  font-weight: 300;
  color: white;
  font-size: 4em;
}

h3 {
  font-weight: 300;
  color: white;
  font-size: 2.5em;
}

#name {
  margin-top: 1em;
  margin-bottom: 0.3em;
}

#title {
  margin-bottom: 1em;
  margin-top: 0.3em;
}

button {
  cursor: pointer;
  border: 1px solid #ffffff;
  background-color: rgba(255,255,255,0);
  color:#ffffff;
  border-radius: 0.4em;
  padding: 1em 1.5em;
  font-weight: bold;
  letter-spacing: 0.1em;
  transition: all 0.3s; 
  margin-right: 1em;
  margin-top: 1em;
  opacity: 0.8;
}

button:hover {
  opacity: 1;
}

#start:hover {
  background-color: #4cd6a0;
}

.hash {
  color: white;
}
</style>
